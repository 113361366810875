import React, {Fragment, useEffect, useState} from "react"
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import {formatCurrencyString} from '../context'
import Moment from 'react-moment'
import 'moment-timezone'
import NumberFormat from 'react-number-format';

import Header from "./Header"
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckIcon from '@material-ui/icons/Check';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3)
        },
        width: '100%'
    },
    input: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        color: '#525f7f',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(',')
    }
}))(InputBase)

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%'
        },
        '& .MuiFormGroup-row': {
            alignItems: 'center'
        },
        '& .MuiFormControl-root': {
            backgroundColor: '#fff',
            border: 'none',
            borderRadius: 4,
            boxShadow: '0 1px 3px 0 rgba(50, 50, 93, 0.15)',
            display: 'block',
            marginBottom: 20
        },
        '& .MuiFormLabel-root': {
            '&.Mui-focused': {
                color: 'rgba(0, 0, 0, 0.54)'
            }
        }
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    bottom: {
        color: '#999',
        animationDuration: '550ms',
        marginLeft: 10
    },
}))

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix="$"
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const Order = ({ id }) => {
    const classes = useStyles()
    const [state, setState] = useState({
        error: null,
        loading: true,
        update: {
            shippingAmount: '',
            shippingCurrency: 'USD',
            shippingMethod: ''
        },
        toggle: {
            shippingCarrier: false,
            trackingNumber: false
        }
    })

    useEffect(() => {
        // Create an scoped async function in the hook
        const getOrder = async () => {
            try {
                const payload = {
                    id
                }

                const response = await fetch(`${process.env.GATSBY_LAMBDA_URL}/.netlify/functions/api-get-order`, {
                    method: 'POST',
                    body: JSON.stringify(payload)
                });
                const results = await response.json();
                setState({...state, ...results, error: null, loading: false});
            } catch (e) {
                console.log(e)
                setState({...state, error: 'This order doesn\'t exist', loading: false});
            }
        }

        // Execute the created function directly
        getOrder();
    }, [])

    const handleUpdate = event => {
        setState({
            ...state,
            update: {
                ...state.update,
                [event.target.name]: event.target.value
            }
        })
    }
    const handleOrder = async (event) => {
        event.preventDefault()
        // Recalculate total
        const total = state.order.subTotal + state.order.salesTax + parseInt(state.update.shippingAmount.replace('.', ''));
        if (state.update) {
            try {
                let payload = {
                    id,
                    ...state.update
                }
                if (state.update.hasOwnProperty('shippingAmount')) payload = {...payload, shippingAmount: parseInt(state.update.shippingAmount.replace('.', '')), total}

                const response = await fetch(`${process.env.GATSBY_LAMBDA_URL}/.netlify/functions/api-admin-update-order`, {
                    method: 'POST',
                    body: JSON.stringify(payload)
                });
                const results = await response.json();
                setState({
                    ...state,
                    order: {
                        ...results.updateOrder
                    },
                    toggle: {
                        ...state.toggle,
                        shippingMethod: false,
                        trackingNumber: false
                    }
                });
            } catch (e) {
                console.log(e)
                setState({
                    ...state,
                    toggle: {
                        ...state.toggle,
                        shippingCarrier: false,
                        trackingNumber: false
                    }
                })
            }
        }
    }
    const handlePickup = async (event) => {
        event.preventDefault()
        try {
            const total = state.order.subTotal + state.order.salesTax;
            let payload = {
                id,
                total,
                readyForPickup: true
            }

            const response = await fetch(`${process.env.GATSBY_LAMBDA_URL}/.netlify/functions/api-admin-update-order`, {
                method: 'POST',
                body: JSON.stringify(payload)
            });
            const results = await response.json();
            setState({
                ...state,
                order: {
                    ...results.updateOrder
                }
            });
        } catch (e) {
            console.log(e)
            setState({
                ...state,
                toggle: {
                    ...state.toggle,
                    shippingCarrier: false,
                    trackingNumber: false
                }
            })
        }
    }

    return (
        <>
            <Box marginBottom={5}>
                <Header/>
            </Box>
            <Container maxWidth={'md'}>
                <Box marginBottom={5}>
                    <Typography variant={'h4'} gutterBottom>Order</Typography>
                </Box>
                {state.loading && (
                    <Box display="flex" justifyContent="center">
                        <CircularProgress
                            variant="indeterminate"
                            disableShrink
                            className={classes.bottom}
                            size={20}
                            thickness={4}
                        />
                    </Box>
                )}
                {!state.loading && (
                    <Fragment>
                        {!state.error && (
                        <>
                            <Box alignItems='center' display='flex'>
                                <Typography variant={'h6'} style={{marginRight: 10}}>Order Id:</Typography>
                                <Typography variant={'inherit'}>{id}</Typography>
                            </Box>
                            <Box alignItems='center' display='flex' marginBottom={5}>
                                <Typography variant={'h6'} style={{marginRight: 10}}>Ordered on:</Typography>
                                <Moment format="LLL">{state.order.createdAt}</Moment>
                            </Box>
                            <Box my={5}>
                                <hr/>
                            </Box>
                            <Box marginBottom={6}>
                                <Grid container alignItems={'center'}>
                                    {state.order.deliveryAddress && (
                                    <Grid item sm={6}>
                                        <Typography variant={'h6'} gutterBottom>{state.order.deliveryMethod === 'pickup' ? 'Pickup Address' : 'Delivery Address'}</Typography>
                                        <Typography>{state.order.deliveryAddress.name}</Typography>
                                        <Typography>{state.order.deliveryAddress.street1}</Typography>
                                        <Typography>{state.order.deliveryAddress.city}, {state.order.deliveryAddress.state} {state.order.deliveryAddress.zip}</Typography>
                                    </Grid>
                                    )}
                                    <Grid item sm={6}>
                                        <Typography variant={'h6'} gutterBottom>Billing Address</Typography>
                                        <Typography>{state.order.billingAddress.name}</Typography>
                                        <Typography>{state.order.billingAddress.street1}</Typography>
                                        <Typography>{state.order.billingAddress.city}, {state.order.billingAddress.state} {state.order.billingAddress.zip}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            {state.order.deliveryMethod === 'delivery' && (
                                <Grid container>
                                    <Grid item sm={6}>
                                        <Typography variant={'h6'}>Shipping Method</Typography>
                                        {state.order.shippingMethod ?
                                            state.order.shippingMethod :
                                            <Fragment>
                                                {!state.toggle.shippingMethod &&
                                                <Button variant={'contained'} color={'secondary'}
                                                        onClick={() => setState({
                                                            ...state,
                                                            toggle: {...state.toggle, shippingMethod: true}
                                                        })}>Add Shipping</Button>}
                                                {state.toggle.shippingMethod && (
                                                    <form
                                                        method="post"
                                                        onSubmit={handleOrder}
                                                    >
                                                        <Box>
                                                            <FormControl style={{marginBottom: 16, marginRight: 16}}>
                                                                <Select
                                                                    name="shippingMethod"
                                                                    value={state.update.shippingMethod}
                                                                    displayEmpty
                                                                    onChange={handleUpdate}
                                                                    input={<BootstrapInput />}
                                                                    required
                                                                >
                                                                    <MenuItem value="" disabled>
                                                                        <Typography>Select...</Typography>
                                                                    </MenuItem>
                                                                    <MenuItem value="UPS Ground">
                                                                        <Typography>UPS Ground</Typography>
                                                                    </MenuItem>
                                                                    <MenuItem value="UPS 3 Day Select®">
                                                                        <Typography>UPS 3 Day Select®</Typography>
                                                                    </MenuItem>
                                                                    <MenuItem value="UPS 2nd Day Air® A.M.">
                                                                        <Typography>UPS 2nd Day Air® A.M.</Typography>
                                                                    </MenuItem>
                                                                    <MenuItem value="UPS 2nd Day Air®">
                                                                        <Typography>UPS 2nd Day Air®</Typography>
                                                                    </MenuItem>
                                                                    <MenuItem value="UPS Freight">
                                                                        <Typography>UPS Freight</Typography>
                                                                    </MenuItem>
                                                                    <MenuItem value="FedEx Express Saver®">
                                                                        <Typography>FedEx Express Saver®</Typography>
                                                                    </MenuItem>
                                                                    <MenuItem value="FedEx 2Day®">
                                                                        <Typography>FedEx 2Day®</Typography>
                                                                    </MenuItem>
                                                                    <MenuItem value="FedEx 2Day® AM">
                                                                        <Typography>FedEx 2Day® AM</Typography>
                                                                    </MenuItem>
                                                                    <MenuItem value="FedEx Standard Overnight®">
                                                                        <Typography>FedEx Standard Overnight®</Typography>
                                                                    </MenuItem>
                                                                    <MenuItem value="FedEx Priority Overnight®">
                                                                        <Typography>FedEx Priority Overnight®</Typography>
                                                                    </MenuItem>
                                                                    <MenuItem value="FedEx First Overnight®">
                                                                        <Typography>FedEx First Overnight®</Typography>
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                            <FormControl style={{marginBottom: 16}}>
                                                                <FormLabel style={{marginRight: 16}}>
                                                                    <BootstrapInput type='text'
                                                                                    name='shippingAmount'
                                                                                    value={state.update.shippingAmount}
                                                                                    onChange={handleUpdate}
                                                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                                                    placeholder='Shipping Cost'
                                                                                    autoComplete={'off'}
                                                                                    required
                                                                                    InputProps={{
                                                                                        inputComponent: NumberFormatCustom,
                                                                                    }}
                                                                    />
                                                                </FormLabel>
                                                            </FormControl>
                                                            <Box>
                                                                <Button type="submit">Save</Button>
                                                                <Button type="button" onClick={() => setState({
                                                                    ...state,
                                                                    toggle: {...state.toggle, shippingMethod: false}
                                                                })}>Cancel</Button>
                                                            </Box>
                                                        </Box>
                                                    </form>
                                                )}
                                            </Fragment>
                                        }
                                    </Grid>
                                    <Grid item sm={6}>
                                        {state.order.shippingMethod && (
                                            <Fragment>
                                                <Typography variant={'h6'} >Tracking Number</Typography>
                                                {state.order.trackingNumber ?
                                                    <a href={`https://google.com/search?q=${state.order.trackingNumber}`} target="_blank">
                                                        <Typography gutterBottom>{state.order.trackingNumber}</Typography>
                                                    </a>
                                                    :
                                                    <Fragment>
                                                        {!state.toggle.trackingNumber && <Button variant={'contained'} color={'secondary'} onClick={() => setState({...state, toggle: {...state.toggle, trackingNumber: true}})}>Add Tracking Number</Button>}
                                                        {state.toggle.trackingNumber && (
                                                            <form
                                                                method="post"
                                                                onSubmit={handleOrder}
                                                            >
                                                                <Box display="flex" alignItems="center">
                                                                    <FormLabel style={{marginRight: 16}}>
                                                                        <BootstrapInput type='text' name='trackingNumber' onChange={handleUpdate} placeholder='Tracking Number' autoComplete={'off'} required />
                                                                    </FormLabel>
                                                                    <Button type="submit">Save</Button>
                                                                    <Button type="button" onClick={() => setState({...state, toggle: {...state.toggle, trackingNumber: false}})}>Cancel</Button>
                                                                </Box>
                                                            </form>
                                                        )}
                                                    </Fragment>
                                                }
                                            </Fragment>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                            {state.order.deliveryMethod === 'pickup' && (
                                <Grid container>
                                    <Grid item>
                                        <Fragment>
                                            <Button variant={'contained'} color={'secondary'}
                                                    onClick={handlePickup}
                                                    disabled={state.order.nextAction === 'ready-for-pickup'}
                                            >
                                                Ready for Pickup
                                                {state.order.nextAction === 'ready-for-pickup' && <CheckIcon style={{marginLeft: 10}} />}
                                            </Button>
                                        </Fragment>
                                    </Grid>
                                </Grid>
                            )}
                            <Box my={5}>
                                <hr/>
                            </Box>
                            <Box marginBottom={5}>
                                {Object.entries(state.order.orderItems).map(([key, item], index) => (
                                    <Box key={index} marginBottom={2}>
                                        <Grid container alignItems={'center'}>
                                            <Grid item sm={3}>
                                                <img className='image' src={item.image} alt={item.name} width={'100%'} style={{marginRight: 10}} />
                                            </Grid>
                                            <Grid item sm={5}>
                                                <Typography variant={'h6'}>{item.name}</Typography>
                                                {Object.entries(item.metadata).map(([key, i], index) => (
                                                    <Fragment key={index}>
                                                        {key !== 'files' && key !== 'parcel' && (
                                                        <Box key={index} alignItems={'center'} display={'flex'}>
                                                            <Typography style={{marginRight: 10}}>{key}:</Typography>
                                                            <Typography>{i}</Typography>
                                                        </Box>
                                                        )}
                                                        {key === 'files' && (
                                                            <Box marginTop={3}>
                                                                {item.metadata.files.map((url, index) => (
                                                                <a key={index} href={url} target="_blank">
                                                                    <Typography>View Artwork</Typography>
                                                                </a>
                                                                ))}
                                                                <a href={item.image} target="_blank">
                                                                    <Typography>View Proof</Typography>
                                                                </a>
                                                            </Box>
                                                        )}
                                                    </Fragment>
                                                ))}
                                            </Grid>
                                            <Grid item sm={2}>
                                                <Typography align={'right'}>Qty: {item.quantity}</Typography>
                                            </Grid>
                                            <Grid item sm={2}>
                                                <Typography align={'right'}>
                                                    {formatCurrencyString({
                                                        value: item.quantity * item.price,
                                                        currency: 'USD',
                                                        language: 'en-US'
                                                    })}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))}
                            </Box>
                            <Grid container>
                                <Grid item sm={6}/>
                                <Grid item sm={6}>
                                    <Box alignItems='center' display='flex' justifyContent={'flex-end'}>
                                        <Typography variant={'h6'} style={{marginRight: 10}}>Subtotal:</Typography>
                                        <Typography variant={'inherit'}>
                                            {formatCurrencyString({
                                                value: state.order.subTotal,
                                                currency: 'USD',
                                                language: 'en-US'
                                            })}
                                        </Typography>
                                    </Box>
                                    {state.order.deliveryMethod === 'delivery' && (
                                        <Box alignItems='center' display='flex' justifyContent={'flex-end'}>
                                            <Typography variant={'h6'} style={{marginRight: 10}}>Shipping:</Typography>
                                            {state.order.shippingAmount ?
                                                formatCurrencyString({
                                                    value: state.order.shippingAmount,
                                                    currency: 'USD',
                                                    language: 'en-US'
                                                }) :
                                                <Typography variant={'inherit'}>Awaiting for shipping</Typography>
                                            }
                                        </Box>
                                    )}
                                    <Box alignItems='center' display='flex' justifyContent={'flex-end'}>
                                        <Typography variant={'h6'} style={{marginRight: 10}}>Tax:</Typography>
                                        <Typography variant={'inherit'}>
                                            {formatCurrencyString({
                                                value: state.order.salesTax,
                                                currency: 'USD',
                                                language: 'en-US'
                                            })}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box my={5}>
                                <hr/>
                            </Box>
                            <Grid container>
                                <Grid item sm={6}/>
                                <Grid item sm={6}>
                                    <Box alignItems='center' display='flex' justifyContent={'flex-end'} marginBottom={2}>
                                        <Typography variant={'h6'} style={{marginRight: 10}}>Order Total:</Typography>
                                        <Typography variant={'inherit'}>
                                            {formatCurrencyString({
                                                value: state.order.total,
                                                currency: 'USD',
                                                language: 'en-US'
                                            })}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </>
                        )}
                        {state.error && (
                            <Typography align={'center'}>{state.error}</Typography>
                        )}
                    </Fragment>
                )}
            </Container>
        </>
    )
}


export default Order

import React from 'react'
import {fade, makeStyles} from '@material-ui/core/styles'
import {Link} from 'gatsby'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import MenuIcon from '@material-ui/icons/Menu'
import {useShoppingCart} from '../context'

import logo from '../../static/signxperts-logo.png'

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

const Header = () => {
  // const setting = data.gcms.setting
    const classes = useStyles()
    const { cartCount } = useShoppingCart()

  return (
      <AppBar position="static" style={{backgroundColor: '#FFF'}}>
          <Toolbar>
              <Link to={'/'}>
                  <img src={logo} alt={'Signxperts Logo'}/>
              </Link>
              <div className={classes.grow}/>
              <div className={classes.sectionDesktop}>
                  <IconButton aria-label="show 17 new notifications" color="default" href='/cart'>
                      <Badge badgeContent={cartCount} color="secondary">
                          <ShoppingCartIcon />
                      </Badge>
                  </IconButton>
              </div>
              <IconButton edge="start" className={classes.sectionMobile} color="default" aria-label="menu">
                  <MenuIcon />
              </IconButton>
          </Toolbar>
      </AppBar>
  )
}

export default Header

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
    isBrowser() && window.sessionStorage.getItem("gatsbyUser")
        ? JSON.parse(window.sessionStorage.getItem("gatsbyUser"))
        : {}

const setUser = user =>
    window.sessionStorage.setItem("gatsbyUser", JSON.stringify(user))

export const handleLogin = async ({ username, password }) => {
    if (username === 'signxperts' && password === '%1121FDIg') {
        await setUser({
            username: 'signxperts',
            name: 'Orders',
            email: 'orders@signxperts.com',
        })

        return true
    }

    return false
}

export const isLoggedIn = () => {
    const user = getUser()

    return !!user.username
}

export const logout = callback => {
    setUser({})
    callback()
}

import React from "react"
import Helmet from 'react-helmet'
import { Router } from '@reach/router'
import Layout from '../layouts'
import PrivateRoute from "../components/privateRoute"
import Order from '../components/order'
import Login from '../components/login'

export default function Admin() {
  return (
      <Layout>
        <Helmet>
          <title>Admin | Signxperts</title>
        </Helmet>
        <Router>
          <PrivateRoute path="/admin/orders/:id" component={Order} />
          <Login path="/admin/" />
        </Router>
      </Layout>
  )
}

import React, {useEffect, useState} from "react"
import { navigate } from "gatsby"
import { handleLogin, isLoggedIn } from "../services/auth"
import queryString from 'query-string'
import {makeStyles, withStyles} from "@material-ui/core/styles";
import InputBase from '@material-ui/core/InputBase'
import Box from '@material-ui/core/Box'
import Typography from "@material-ui/core/Typography";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import Alert from '@material-ui/lab/Alert';
import Header from "../components/Header";

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3)
        },
        width: '100%'
    },
    input: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        color: '#525f7f',
        fontSize: 16,
        marginBottom: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(',')
    }
}))(InputBase)

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 100,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 100,
        width: 500,
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%'
        },
        '& .MuiFormGroup-row': {
            alignItems: 'center'
        },
        '& .MuiFormControl-root': {
            backgroundColor: '#fff',
            border: 'none',
            borderRadius: 4,
            boxShadow: '0 1px 3px 0 rgba(50, 50, 93, 0.15)',
            display: 'block',
            marginBottom: 20
        },
        '& .MuiFormLabel-root': {
            '&.Mui-focused': {
                color: 'rgba(0, 0, 0, 0.54)'
            }
        }
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    bottom: {
        color: '#999',
        animationDuration: '550ms',
        marginLeft: 10
    },
}))

const Login = ({ location }) => {
    const classes = useStyles()
    const [state, setState] = useState({
        orderNumber: '',
        phone: '',
        error: false
    })
    const {redirect_uri} = queryString.parse(location.search)

    useEffect(() => {
        if (isLoggedIn()) {
            navigate(redirect_uri)
        }
    }, [])

    const handleUpdate = event => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
            error: false
        })
    }

    const handleSubmit = async event => {
        event.preventDefault()
        const isMatched = await handleLogin(state)
        if (isMatched) navigate(redirect_uri)
        else setState({...state, error: true})
    }

    return (
        <>
            <Header/>
            <div className={classes.root}>
                <Typography variant={'h4'} gutterBottom>Log in</Typography>
                <form
                    method="post"
                    onSubmit={handleSubmit}
                >
                    <Box>
                        <FormLabel>
                            <BootstrapInput type='text' name='username' onChange={handleUpdate} placeholder='Username' required />
                        </FormLabel>
                        <FormLabel>
                            <BootstrapInput type='password' name='password' onChange={handleUpdate} placeholder='Password' autoComplete={'off'} required />
                        </FormLabel>
                    </Box>
                    {state.error && (
                        <Box marginBottom={5}>
                            <Alert variant="filled" severity="error">
                                Incorrect username or password.
                            </Alert>
                        </Box>
                    )}
                    <Box marginBottom={5} align={'right'}>
                        <Button type={'submit'} variant={'contained'} color={'secondary'}>
                            Log In
                        </Button>
                    </Box>
                </form>
            </div>
        </>
    )
}

export default Login
